import React from 'react';
import { node, object, string } from 'prop-types';
import classNames from 'classnames';
import { propTypes } from '../../../util/types';
import { ListingCard, ListingCardMobile } from '../../../components';
import { Instagram } from 'react-content-loader';
import InfiniteScroll from 'react-infinite-scroll-component';
import { isArrayLength } from '../../../util/dataExtractors';

import css from './SearchResultsPanel.module.css';

const SearchResultsPanel = props => {
  const {
    className,
    rootClassName,
    listings,
    pagination,
    searchInProgress,
    isMobile,
    handleLoadMore,
    searchInURL,
    hasNoResult = false,
  } = props;

  const classes = classNames(rootClassName || css.root, className);

  const listingCard = l => {
    return isMobile ? (
      <ListingCardMobile className={css.listingCard} key={l?.id?.uuid} listing={l} />
    ) : (
      <ListingCard className={css.listingCard} key={l?.id?.uuid} listing={l} />
    );
  };

  const onScrollLoad = () => {
    const nextSearchParams = { ...searchInURL, page: pagination.page + 1 };
    handleLoadMore(nextSearchParams);
  };

  const instagramLoaders = Array(20)
    .fill()
    .map((_, index) => <Instagram key={index} />);

  if (hasNoResult) {
    return <></>;
  }

  return (
    <div className={classes}>
      <div className={css.listingCards}>
        {!searchInProgress && pagination ? (
          <InfiniteScroll
            hasMore={pagination.page < pagination.totalPages}
            next={onScrollLoad}
            dataLength={listings?.length || 1000}
            scrollThreshold={0.2}
          >
            {isArrayLength(listings) && listings?.map(l => listingCard(l))}
          </InfiniteScroll>
        ) : (
          <div className={css.productLoaders}>{instagramLoaders}</div>
        )}
        {props.children}
      </div>
    </div>
  );
};

SearchResultsPanel.defaultProps = {
  children: null,
  className: null,
  listings: [],
  pagination: null,
  rootClassName: null,
  search: null,
};

SearchResultsPanel.propTypes = {
  children: node,
  className: string,
  pagination: propTypes.pagination,
  rootClassName: string,
  search: object,
};

export default SearchResultsPanel;
