import React, { createRef, useState } from 'react';
import { bool, func, object, node, number, shape, string } from 'prop-types';
import classNames from 'classnames';
import { withRouter } from 'react-router-dom';

import routeConfiguration from '../../../routing/routeConfiguration';
import { FormattedMessage, injectIntl, intlShape } from '../../../util/reactIntl';
import { createResourceLocatorString } from '../../../util/routes';

import {
  ModalInMobile,
  Button,
  CustomSearchForm,
  SearchQuickFilters,
  Icons,
} from '../../../components';

import IconFilters from '../../../components/IconFilters/IconFilters';
import SortByWrapper from './SortByWrapper';

import css from './SearchFiltersMobile.module.css';
import { isRentalSearchPage } from '../../../util/dataExtractors';
import { stringifyDateToISO8601 } from '../../../util/dates';
import { DateRangePicker } from 'react-dates';
import moment from 'moment';

const SearchFiltersMobile = ({
  rootClassName,
  className,
  children,
  sortByComponent,
  resultsCount,
  showAsModalMaxWidth,
  onManageDisableScrolling,
  noResultsInfo,
  intl,
  history,
  urlQueryParams,
  quickFilters,
  onOpenFilterModal,
  resetAll: resetAllProp,
}) => {
  // State for managing date picker
  const [isPickerOpen, setIsPickerOpen] = useState(false);
  const [dates, setDates] = useState({ startDate: null, endDate: null });
  const [focusedInput, setFocusedInput] = useState(null);
  const [isFiltersOpenOnMobile, setIsFiltersOpenOnMobile] = useState(false);
  const [initialQueryParams, setInitialQueryParams] = useState(null);

  // Ref for keyword input field
  const keywordRef = createRef();

  // Open filters modal, set the initial parameters to current ones
  const openFilters = () => {
    const { onOpenModal } = props;
    onOpenModal();
    setIsFiltersOpenOnMobile(true);
    setInitialQueryParams(urlQueryParams);
  };

  // Close the filters by clicking cancel, revert to the initial params
  const cancelFilters = () => {
    const { onCloseModal } = props;

    history.push(
      createResourceLocatorString('SearchPage', routeConfiguration(), {}, initialQueryParams)
    );
    onCloseModal();
    setIsFiltersOpenOnMobile(false);
    setInitialQueryParams(null);
  };

  // Close the filter modal
  const closeFilters = () => {
    props.onCloseModal();
    setIsFiltersOpenOnMobile(false);
  };

  // Reset all filter query parameters
  const resetAll = e => {
    resetAllProp(e);

    // blur event target if event is passed
    if (e && e.currentTarget) {
      e.currentTarget.blur();
    }
  };

  // Handler for submitting search form
  const handleSubmit = values => {
    const { keywords } = values;
    history.push(createResourceLocatorString('SearchPage', routeConfiguration(), {}, { keywords }));
  };

  // Handler for toggling date picker visibility
  const togglePicker = () => {
    setIsPickerOpen(!isPickerOpen);
    // Automatically focus on the start date input when opening the picker
    if (!isPickerOpen) setFocusedInput('startDate');
    else setFocusedInput(null); // This will hide the picker
  };

  // Handler for date change in date picker
  const handleDateChange = ({ startDate, endDate }) => {
    setDates({ startDate, endDate });
    const formattedDates = stringifyDateToISO8601({ startDate, endDate });
    const extraParams = urlQueryParams
      ? { ...urlQueryParams, dates: formattedDates }
      : { dates: formattedDates };
    startDate &&
      endDate &&
      history.push(
        createResourceLocatorString(
          'CostumeRentalsSearchPage',
          routeConfiguration(),
          {},
          extraParams
        )
      );
  };

  const isRentals = isRentalSearchPage();

  // Rendering component
  return (
    <div className={classNames(rootClassName || css.root, className)}>
      <div className={css.mobileSearchFilterBar}>
        {/* This keyword search is being use for desktop as well */}
        <CustomSearchForm
          className={css.filterSearchBox}
          onSubmit={handleSubmit}
          intl={intl}
          ref={keywordRef}
        />
        <div className={css.sortyByWrapper}>
          <SortByWrapper
            resetAll={() => {
              if (keywordRef.current) {
                keywordRef.current.value = '';
              }
              resetAllProp();
              setDates({ startDate: null, endDate: null });
            }}
          />
          <span className={css.moreFilters} onClick={togglePicker}>
            <Icons name="calendar" />
            {dates.startDate ? <span className={css.count}>1</span> : null}
          </span>
          <span className={css.moreFilters} onClick={onOpenFilterModal}>
            <IconFilters />
          </span>
          {sortByComponent}
        </div>
        {isPickerOpen && (
          <DateRangePicker
            startDate={dates.startDate}
            startDateId="start_date"
            endDate={dates.endDate}
            endDateId="end_date"
            onDatesChange={handleDateChange}
            focusedInput={focusedInput}
            hideKeyboardShortcutsPanel
            numberOfMonths={1}
            isOutsideRange={day => day.isBefore(moment().startOf('day'))}
            onFocusChange={focused => {
              setFocusedInput(focused);
              if (!focused) setIsPickerOpen(false);
            }}
            orientation={'vertical'}
            navPrev={<Icons name="leftAngle" />}
            navNext={<Icons name="rightAngle" />}
          />
        )}
      </div>
      <SearchQuickFilters isCostumes />
      {noResultsInfo && noResultsInfo}
      <ModalInMobile
        id="SearchFiltersMobile.filters"
        isModalOpenOnMobile={isFiltersOpenOnMobile}
        onClose={cancelFilters}
        showAsModalMaxWidth={showAsModalMaxWidth}
        onManageDisableScrolling={onManageDisableScrolling}
        containerClassName={css.modalContainer}
        closeButtonMessage={intl.formatMessage({ id: 'SearchFiltersMobile.cancel' })}
      >
        <div className={css.modalHeadingWrapper}>
          <span className={css.modalHeading}>
            {intl.formatMessage({ id: 'SearchFiltersMobile.heading' })}
          </span>
          <button className={css.resetAllButton} onClick={e => resetAll(e)}>
            <FormattedMessage id={'SearchFiltersMobile.resetAll'} />
          </button>
        </div>
        {isFiltersOpenOnMobile && <div className={css.filtersWrapper}>{children}</div>}
        <div className={css.showListingsContainer}>
          <Button className={css.showListingsButton} onClick={closeFilters}>
            {intl.formatMessage(
              { id: 'SearchFiltersMobile.showListings' },
              { count: resultsCount }
            )}
          </Button>
        </div>
      </ModalInMobile>
    </div>
  );
};

SearchFiltersMobile.defaultProps = {
  rootClassName: null,
  className: null,
  sortByComponent: null,
  resultsCount: null,
  searchInProgress: false,
  selectedFiltersCount: 0,
  isMapVariant: true,
  filtersText: 'Filters',
};

SearchFiltersMobile.propTypes = {
  rootClassName: string,
  className: string,
  urlQueryParams: object.isRequired,
  sortByComponent: node,
  listingsAreLoaded: bool.isRequired,
  resultsCount: number,
  searchInProgress: bool,
  showAsModalMaxWidth: number.isRequired,
  onMapIconClick: func.isRequired,
  onManageDisableScrolling: func.isRequired,
  onOpenModal: func.isRequired,
  onCloseModal: func.isRequired,
  resetAll: func.isRequired,
  selectedFiltersCount: number,
  isMapVariant: bool,

  // from injectIntl
  intl: intlShape.isRequired,

  // from withRouter
  history: shape({
    push: func.isRequired,
  }).isRequired,
};

export default injectIntl(withRouter(SearchFiltersMobile));
