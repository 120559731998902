import React, { useEffect, useRef, useState } from 'react';
import { bool, node, number, string } from 'prop-types';
import classNames from 'classnames';
import IconFilters from '../../../components/IconFilters/IconFilters';
import { createResourceLocatorString } from '../../../util/routes';
import routeConfiguration from '../../../routing/routeConfiguration';
import { CustomSearchForm, Icons, SearchQuickFilters } from '../../../components';
import IconClearFilters from '../../../components/IconClearFilters/IconClearFilters';

import css from './MainPanelHeader.module.css';
import { useMyContext } from '../../../contexts/StateHolder';
import { DateRangePicker } from 'react-dates';
import { IS_COSTUMES_RENTALS } from '../../../util/enums';
import { stringifyDateToISO8601 } from '../../../util/dates';
import moment from 'moment';
import { isRentalSearchPage } from '../../../util/dataExtractors';
import { useLocation } from 'react-router-dom/cjs/react-router-dom.min';
import { parse } from '../../../util/urlHelpers';

const MODAL_BREAKPOINT = 768;
const isWindowDefined = typeof window !== 'undefined';
const isMobileLayout = isWindowDefined && window.innerWidth < MODAL_BREAKPOINT;

const MainPanelHeader = props => {
  const {
    rootClassName,
    className,
    children,
    sortByComponent,
    noResultsInfo,
    onOpenFilterModal,
    quickFilters,
    filtersText,
    intl,
    history,
    viewport,
    clearText,
    hasAnyFilter,
    basePage = 'SearchPage',
    resetAll,
    urlQueryParams,
  } = props;

  const keywordRef = useRef(null);
  const location = useLocation();
  const search = parse(location.search);
  const { clearDeepFilter, setClearDeepFilters } = useMyContext();

  const classes = classNames(rootClassName || css.root, className);

  const handleSubmit = values => {
    const { keywords } = values;
    const extraParams = urlQueryParams ? { ...urlQueryParams, keywords } : { keywords };
    history.push(createResourceLocatorString(basePage, routeConfiguration(), {}, extraParams));
  };

  const keywordsIfAny = search?.keywords;

  useEffect(() => {
    if (clearDeepFilter && !keywordsIfAny) {
      keywordRef.current.value = '';
    } else if (keywordsIfAny) {
      keywordRef.current.value = keywordsIfAny;
    }
  }, [keywordsIfAny, clearDeepFilter]);

  const [isPickerOpen, setIsPickerOpen] = useState(false);
  const [dates, setDates] = useState({
    startDate: null,
    endDate: null,
  });
  const [focusedInput, setFocusedInput] = useState(null);

  const formatValue = dates => {
    const { startDate, endDate } = dates || {};
    const start = startDate && stringifyDateToISO8601(startDate);
    const end = endDate && stringifyDateToISO8601(endDate);
    return start && end ? `${start},${end}` : null;
  };

  const handleDateChange = ({ startDate, endDate }) => {
    setDates({ startDate, endDate });
    const dates = formatValue({ startDate, endDate });
    const extraParams = urlQueryParams ? { ...urlQueryParams, dates } : { dates };
    startDate &&
      endDate &&
      history.push(
        createResourceLocatorString(
          'CostumeRentalsSearchPage',
          routeConfiguration(),
          {},
          extraParams
        )
      );
  };

  const togglePicker = () => {
    setIsPickerOpen(!isPickerOpen);
    // Automatically focus on the start date input when opening the picker
    if (!isPickerOpen) setFocusedInput('startDate');
    else setFocusedInput(null); // This will hide the picker
  };

  const isRentals = isRentalSearchPage();

  return (
    <div className={classes}>
      <div className={css.searchOptions}>
        <SearchQuickFilters isCostumes />
        <div className={css.sortyByWrapper}>
          <span
            className={classNames(css.clearFilters, {
              [css.active]: hasAnyFilter,
            })}
            onClick={() => {
              if (keywordRef && keywordRef.current) {
                keywordRef.current.value = '';
              }
              setClearDeepFilters(true);
              resetAll();
              setDates({
                startDate: null,
                endDate: null,
              });
            }}
          >
            <IconClearFilters /> {clearText}
          </span>
          <CustomSearchForm
            className={css.filterSearchBox}
            onSubmit={handleSubmit}
            intl={intl}
            ref={keywordRef}
          />

          <div className={css.rightFilters}>
            {isRentals ? (
              <div className={css.moreFilters} onClick={togglePicker}>
                <Icons name="calendar" /> Date
                {dates?.startDate ? <span className={css.count}>1</span> : null}
                {isPickerOpen ? (
                  <DateRangePicker
                    startDate={dates?.startDate}
                    startDateId="start_date"
                    endDate={dates?.endDate}
                    endDateId="end_date"
                    onDatesChange={handleDateChange}
                    focusedInput={focusedInput}
                    hideKeyboardShortcutsPanel
                    isOutsideRange={day => day.isBefore(moment().startOf('day'))}
                    onFocusChange={focused => {
                      setFocusedInput(focused);
                      if (!focused) setIsPickerOpen(false); // Automatically hide picker if focus is lost
                    }}
                    orientation={isMobileLayout ? 'vertical' : 'horizontal'}
                    navPrev=<Icons name="leftAngle" />
                    navNext=<Icons name="rightAngle" />
                  />
                ) : null}
              </div>
            ) : null}
            <span className={css.moreFilters} onClick={onOpenFilterModal}>
              <IconFilters /> {filtersText}
            </span>
            {sortByComponent}
          </div>
        </div>
      </div>
      {children}
      {noResultsInfo ? noResultsInfo : null}
    </div>
  );
};

MainPanelHeader.defaultProps = {
  rootClassName: null,
  className: null,
  resultsCount: null,
  searchInProgress: false,
  sortByComponent: null,
  filtersText: 'Filters',
  clearText: 'Clear',
};

MainPanelHeader.propTypes = {
  rootClassName: string,
  className: string,
  listingsAreLoaded: bool.isRequired,
  resultsCount: number,
  searchInProgress: bool,
  sortByComponent: node,
};

export default MainPanelHeader;
